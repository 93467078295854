import Homepage from "@containers/Homepage/containers";
import Layout from "@layout/Layout.js";
import Head from "next/head";
import React from "react";
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import {
//   getAllTalks,
//   getBestPicksArticles,
//   getSuggestedArticles,
//   getNeedHelpArticles,
//   getRecentArticles,
// } from '../helpers/api'
import Home from '../src/containers/Homepage/containers/Home'

// export default function Index({ bestPicksArticles,
//                                     suggestedArticles,
//                                     needHelpArticles,
//                                     recentArticles,
//                                     Talks }) {
export default function Index() {

  const tablet = useMediaQuery('(max-width:959px)');
  const notTablet = useMediaQuery('(min-width:959px)');


  return (
    <>
      <Head>
        <title>hackerpost  – write your hacks</title>
        <meta
          name="description"
          content="hackerpost  write your hacks. No Login required."
        />
        <meta property="og:title" content="hackerpost  – write your hacks" />
        <meta property="og:description" content="hackerpost  write your hacks. No Login required." />
        <meta property="og:url" content="https://hackerpost.in" />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary"></meta>
        <meta name="twitter:site" content="@hackerpost" />
        <meta name="twitter:title" content="hackerpost  –write your hacks" />
        <meta name="twitter:description" content="hackerpost  write your hacks. No Login required."></meta>
        <meta
          name="twitter:image"
          content='https://hackerpost.in/img/plain.png'
        />{" "}
        <meta property="og:image" content='https://hackerpost.in/img/plain.png' />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-DLDTYQLBCK"></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-DLDTYQLBCK');
                            `,
          }}
        />
      </Head>

      <Layout>
        {/* <div className="marquee"><p>We will be under maintenance on tomorrow...From Time EST 4am- 4:30am</p></div> */}
        <Home
          matches={tablet}
          notMatches={notTablet}
        />
      </Layout>
    </>
  );
}

// export async function getServerSideProps({ query, preview = false }) {

//   const Talks = (await getAllTalks(preview)) ?? []

//   const bestPicksArticles = (await getBestPicksArticles()) ?? []
//   const suggestedArticles = (await getSuggestedArticles()) ?? []
//   const needHelpArticles = (await getNeedHelpArticles()) ?? []
//   const recentArticles = (await getRecentArticles()) ?? []

//   return {
//     props: {
//       bestPicksArticles,
//       suggestedArticles,
//       needHelpArticles,
//       recentArticles,
//       Talks,
//     },
//   }
// }