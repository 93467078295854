import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import NewIntro from './NewIntro';
import BestPicks from './BestPicks';
import { makeStyles } from "@material-ui/core/styles";
import dynamic from 'next/dynamic';
import RecentArticles from './RecentArticles';

const Popular = dynamic(() => import('./Popular'))
const FollowedTagArticles = dynamic(() => import('./FollowedTagArticles'))
// const SuggestedArticles = dynamic(() => import('./SuggestedArticles'))
const NeedHelpArticles = dynamic(() => import('./NewNeedHelp'))

const useStyles = makeStyles((theme) => ({
    relative: {
        position: "relative",
        top: "-6px",
        ['@media (min-width:768px)']: {
            position: "relative",
            top: "-12px"
        },
    },
    SkeletonCard: {
        backgroundColor: 'grey',
        borderRadius: '0.25rem',
        width: '100%',
        margin: "4px 0 8px 0"
    },
    margin: {
        margin: 'auto'
    },
    link: {
        textDecoration: 'none'
    },
    flex: {
        display: "flex"
    },
}));

function Home(props) {
    const classes = useStyles();
    const [tagsQuery, setTagsQuery] = useState('');
    const [bestPicksArticles, setBestPicksArticles] = useState([]);
    const [recentArticles, setRecentArticles] = useState([]);
    const [suggestedArticles, setSuggestedArticles] = useState([]);
    const [needHelpArticles, setNeedHelpArticles] = useState([]);
    const [allTalks, setAllTalks] = useState([]);
    const [show, setShow] = useState(false);

    async function fetchArticleHome(url) {
        return fetch(url)
            .then((response) => response.json())
            .catch(err => console.log(err))
    }

    async function getBestPicksArticles() {
        let url = `/api/api/v1/best-pick-article-for-home-page/`
        const article = await fetchArticleHome(url)
        setBestPicksArticles(article);
    }

    // async function getSuggestedArticles() {
    //     let url = `/api/api/v1/suggested-article-for-home-page/`
    //     const article = await fetchArticleHome(url)
    //     setSuggestedArticles(article)
    // }

    async function getNeedHelpArticles() {
        let url = `/api/api/v1/all-need-article-for-home-page/`
        const article = await fetchArticleHome(url)
        setNeedHelpArticles(article)
    }

    async function getRecentArticles() {
        let url = `/api/api/v1/recent-article-for-home-page/`
        const article = await fetchArticleHome(url)
        console.log(article)
        setRecentArticles(article)
        setShow(true);
    }

    const myHomePageArticles = () => {
        getRecentArticles();
        getBestPicksArticles();
        // getSuggestedArticles();
        getNeedHelpArticles();
    }

    async function getAllTalks() {
        const talk = await fetchArticleHome(`/api/api/v1/list-all-videos/`)
        setAllTalks(talk.results);
    }

    useEffect(() => {
        myHomePageArticles();
        getAllTalks();
    }, [])

    let allArticles = !show ?
        <>
            {
                (Array.from(new Array(2))).map((item, index) => (
                    <Grid container justify="center" alignItems="center" position="flex">
                        <Grid item lg={12} xs={12} md={12}>
                            <Box key={index} width="96%" mb={2} className={classes.margin}>
                                <Skeleton
                                    variant="rect"
                                    width="100%"
                                    height={118}
                                    className={classes.SkeletonCard}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                ))
            }
        </> :
        <>
            <RecentArticles
                recentArticles={recentArticles.recent_pick}
            />
            
        </>


    return (
        <Grid container justify="space-between" alignItems="flex-start" className={classes.relative}>
            <Grid item xs={12} sm={8} lg={8}>
                <NewIntro matches={props.matches} />
                {allArticles}
            </Grid>
            {props.notMatches &&
                <>
                    <Popular bestPicksArticles={bestPicksArticles} matches={!props.matches} />
                </>}
        </Grid>
    )
}

export default Home;