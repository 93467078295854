import React, { useEffect } from "react";
import uuid from 'react-uuid';
import * as utils from '../Utils/utils';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useRouter } from 'next/router'
import Fab from '@material-ui/core/Fab';
import dynamic from 'next/dynamic';

const Navbar = dynamic(() => import('@shared/Components/Navbar/Navbar'))


const useStyles = makeStyles((theme) => ({
  floatingIcon: {
    position: "fixed",
    right: "16px",
    bottom: "50px", backgroundColor: "#0FAE4F",
  }
}))

function Layout(props) {
  const mobileMenu = useMediaQuery('(max-width:767px)');
  const classes = useStyles();
  const router = useRouter();
  

  const handleRouteToStartWriting = () => {
    router.push('/StartWriting')
  }

  useEffect(() => {
    const id = uuid();
    utils.getSession(id)
  }, []); 

  return (
    <div>
      <Navbar {...props}>{props.children}</Navbar>
      {mobileMenu &&
        <Fab color="secondary" aria-label="edit" className={classes.floatingIcon} size={"medium"}onClick={handleRouteToStartWriting}>
        <img src={'/img/write_fab.png'} height="22" width="22" loading="lazy"/>
        </Fab>
      }
    </div>
  );
}

export default Layout;