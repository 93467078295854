import React from 'react'
import Grid from '@material-ui/core/Grid';
import { useStyles } from './styles'
import { useRouter } from 'next/router'

function NewIntro(props) {

    const classes = useStyles();
    const router = useRouter();

    const handleRouteToStartWriting = () => {
        router.push('/StartWriting')
    }

    return (
        <Grid container direction="column">
            <Grid item xs sm lg >
                <div className={classes.startWritingInput}>
                        <div className={classes.textAlign}>
                           Hacked something? Share to the world!
                        </div>
                    <div className={classes.buttonPosition}>
                        <div className={classes.writtingButton} onClick={handleRouteToStartWriting}>
                            Start Writting
                        </div>
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}

export default NewIntro;