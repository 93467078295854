import axios from 'axios'
// "2021-02-03T21:03:16Z"


export const analyse = (id, url) => {

    setTimeout(async() => {
        try {
            const analyseURL = await axios.get(`${url}`);
        } catch (e) {
            console.log(e)
        }

        requestAnimationFrame(() => {
            analyse(id, url);
        });

    }, 3000)

}

export const postSession = (uid, start_time) => {
    // setTimeout(async() => {
    //     try {
    //         let current_time = new Date()
    //         let endTime = `${current_time.getUTCFullYear()}-${current_time.getUTCMonth()+1}-${current_time.getUTCDate()}T${current_time.getUTCHours()}:${current_time.getUTCMinutes()}:${current_time.getUTCSeconds()}Z`
    //         const analyseURL = await axios.post('/api/api/analytics/complete_page_session/', {
    //             "unique_id": uid,
    //             "start_time": start_time,
    //             "end_time": endTime
    //         });
    //     } catch (e) {
    //         console.log(e)
    //     }

    //     requestAnimationFrame(() => {
    //         postSession(uid, start_time);
    //     });

    // }, 3000)

}


export const handleTime = (time) => {
    var newTime = '';
    let TimeArray = [];
    TimeArray = time.toString().split(':');

    let x = [];
    for (let i = 0; i < TimeArray.length; i++) {
        let n = TimeArray[i];
        x.push(parseInt(n));
    }

    let newArr = [];
    newArr[0] = 'D ';
    newArr[1] = 'h ';
    newArr[2] = 'm ';

    for (let j = 0; j < newArr.length; j++) {
        if (x[j] > 0 && x[j] !== undefined) {
            newTime += x[j] + newArr[j];
        }
    }

    return newTime;
}


function postSessionWithRequestAnimationFrame(uid, startTime) {
    var current_time = new Date(startTime)
    let updatedTime = `${current_time.getUTCFullYear()}-${current_time.getUTCMonth()+1}-${current_time.getUTCDate()}T${current_time.getUTCHours()}:${current_time.getUTCMinutes()}:${current_time.getUTCSeconds()}Z`

    requestAnimationFrame(() => {
        postSession(uid, updatedTime);
    });
}


function getDataFromSessionStorage() {
    let sessionId = sessionStorage.getItem("v_session_id");
    let startTime = sessionStorage.getItem("v_session_start_time");

    return { sessionId, startTime }
}


export const getSession = (uid) => {
    let { sessionId, startTime } = getDataFromSessionStorage();
    if (sessionId && startTime) {
        postSessionWithRequestAnimationFrame(sessionId, startTime)
    } else {
        sessionStorage.setItem("v_session_id", uid)
        sessionStorage.setItem("v_session_start_time", new Date())

        let sid = sessionStorage.getItem("v_session_id");
        let stime = sessionStorage.getItem("v_session_start_time");

        postSessionWithRequestAnimationFrame(sid, stime)
    }
}

