import React from 'react'
import Grid from '@material-ui/core/Grid';
import Header from './Header'
import ArticleCard from '../Components/MainCard/Card';
import { useStyles } from './styles';
import RedirectIcon from '../../../shared/Components/redirectIcon';
import Link from 'next/link';

function RecentArticles(props) {

    const classes = useStyles();

    let renderArticleData = [];
    if (props.recentArticles && props.recentArticles.length !== 0) {
        for (let i = 0; i < props.recentArticles.length; i++) {
                renderArticleData[i] = (
                        <ArticleCard
                            key={i}
                            borderColor='#0FAE4F'
                            bgColor="#d4d4d4fa"
                            {...props.recentArticles[i]}
                        />
                )
        }
    }

    return (
        <Grid container justify="space-between" alignItems="center">
            <Grid item lg={12} xs={12} md={12}>
                <div className={classes.flex}>
                    <Link href={'/recent-posts'} prefetch={false}>
                        <a className={classes.link}>
                            <Header
                                titleColor='#0FAE4F'
                                header="Posts from Hackerpost" />
                        </a>
                    </Link>
                    <RedirectIcon link="/recent-posts" /> { /** seperate in constants */}
                </div>
                {renderArticleData}
                {

                }
            </Grid>
        </Grid>
    )
}

export default RecentArticles;