import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import dynamic from 'next/dynamic';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchAnnouncements } from '../../../../store/Actions/Homepage/announcementActions';
import * as actions from '../../../../store/Actions/Homepage/filterAction';
import * as utils from '../../../Utils/utils';
import VideoSession from '../../VideoSession';
import Intro from './Intro';

const CardChangeForScreenSize = dynamic(() => import('../Components/MainCard/CardChangeForScreenSize'), {
  ssr: false,
})

const Filters = dynamic(() => import('./Filters'), {
  ssr: false,
})

const Popular = dynamic(() => import('./Popular'), {
  ssr: false,
})

class Homepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      homepageArticles: [],
      TotalPosts: [],
      isLoading: false,
      hide: false,
    };
  }

  handleObserver = (entities, observer) => {
    if (entities[0].intersectionRatio > 0) {
      console.log('seen');
      console.log('newest')
      if (this.props.next !== null) {

        this.props.fetchNewestArticles(this.props.next).then(() => {
          this.setState({
            homepageArticles: [...this.props.sortedArticles, ...this.props.homepageArticles]
          })
        })
      }
      else {
        this.setState({ hide: true })
      }
    }
  };


  componentDidMount() {
    this.props.fetchAnnouncements();

    var options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };

    this.observer = new IntersectionObserver(
      this.handleObserver.bind(this),
      options
    );
    this.observer.observe(this.loadingRef);

  }

  render() {

    const {
      classes,
      matches,
      status,
      anouncements,
      TotalPosts,
    } = this.props;

    const {
      hide,
      homepageArticles
    } = this.state;

    let renderArticleData = [];
    let j = 0;
    let x = 3;

    if (this.state.homepageArticles.length === 0) {
      for (let i = 0; i < this.props.sortedArticles.length; i++) {
        if (i === x && anouncements && j < anouncements.length) {
          renderArticleData[i] = (
            <>
              <CardChangeForScreenSize
                key={j * 4}
                bgColor="#fff"
                {...anouncements[j]}
                anouncement
              />
              <CardChangeForScreenSize
                key={i}
                bgColor="#1F2022"
                {...this.props.sortedArticles[i]}
              />
            </>
          );
          x += 5;
          j += 1;
        } else {
          renderArticleData[i] = (
            <CardChangeForScreenSize
              key={i * 12}
              bgColor="#1F2022"
              {...this.props.sortedArticles[i]}
            />
          );
        }
      }
    } else {
      for (let i = 0; i < homepageArticles.length; i++) {
        if (i === 2) {
          renderArticleData[i] = (
            <>
              {matches && <VideoSession talks={this.props.talks}/>}

              <CardChangeForScreenSize
                key={i}
                bgColor="#1F2022"
                {...homepageArticles[i]}
              />
            </>
          );
        }
        else if (i === x && anouncements && j < anouncements.length) {
          renderArticleData[i] = (
            <>
              <CardChangeForScreenSize
                key={j * 4}
                bgColor="#fff"
                {...anouncements[j]}
                anouncement
              />
              <CardChangeForScreenSize
                key={i}
                bgColor="#1F2022"
                {...homepageArticles[i]}
              />
            </>
          );
          x += 5;
          j += 1;
        } else {
          renderArticleData[i] = (
            <CardChangeForScreenSize
              key={i * 12}
              bgColor="#1F2022"
              {...homepageArticles[i]}
            />
          );
        }
      }
    }

    return (
      <>

        <Grid container spacing={2} className={classes.root}>
          <Intro matches={matches} />

          <Filters
            handleFilters={this.handleFilters}
            TotalPosts={TotalPosts}
            userCount={this.props.homePageUserCount}
            timeSpent={utils.handleTime(this.props.homePageTimeSpent)}
          />
          <Grid item lg={8} xs={12} md={8}>
            {status === 'loading' ? (
              <Box width="100%" mb={2}>
                <Skeleton
                  variant="rect"
                  width="100%"
                  height={118}
                  className={classes.SkeletonCard}
                />
              </Box>
            ) : (
              <Box width="100%" mb={2}>
                {/* {matches && <VideoSession />} */}

                {renderArticleData}
              </Box>
            )}
            {!hide && (
              <div ref={(loadingRef) => (this.loadingRef = loadingRef)}>
                <Box width="100%" mb={2}>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={118}
                    className={classes.SkeletonCard}
                  />
                </Box>
              </div>
            )}
          </Grid>
          {/* Popular section. It will be only shown in desktop devices. */}
          {!matches && <Popular talks={this.props.talks}/>}
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  firstTenArticles: state.firstTenArticlesReducer.firstTenArticles,
  status: state.firstTenArticlesReducer.status,
  anouncements: state.announcementReducer.anouncements,
  filterBy: state.filteredArticleReducers.filterBy,
  next: state.filteredArticleReducers.next,
  homepageArticles: state.filteredArticleReducers.homepageArticles
})

const mapDispatchToProps = (dispatch) => {
  return {
    fetchFirstTenArticles: bindActionCreators(actions.fetchFirstTenArticles, dispatch),
    fetchAnnouncements: bindActionCreators(fetchAnnouncements, dispatch),
    chooseBetweenFilters: bindActionCreators(actions.chooseBetweenFilters, dispatch),
    fetchNewestArticles: bindActionCreators(actions.fetchNewestArticles, dispatch),
    fetchMaxViewsArticles: bindActionCreators(actions.fetchMaxViewsArticles, dispatch),
    fetchMinViewsArticles: bindActionCreators(actions.fetchMinViewsArticles, dispatch)
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Homepage);